//close nav-item
$(function () {
	var Accordion = function (el, multiple) {
		this.el = el || {};
		this.multiple = multiple || false;

		var links = this.el.find('.link');

		links.on('click', { el: this.el, multiple: this.multiple }, this.dropdown)
	}

	Accordion.prototype.dropdown = function (e) {
		var $el = e.data.el;
		$this = $(this),
			$next = $this.next();

		$next.slideToggle();
		$this.parent().toggleClass('open');

		if (!e.data.multiple) {
			$el.find('.submenu').not($next).slideUp().parent().removeClass('open');
		}
		else {
			$el.find('.submenu').not($next).slideUp().parent().removeClass('close');
		};
	}

	var accordion = new Accordion($('#accordion'), false);
});

//close nav-item

// Sticky Navbar
$(window).scroll(function () {
	if ($(this).scrollTop() > 90) {
		$('#nav').addClass('nav-sticky');
	} else {
		$('#nav').removeClass('nav-sticky');
	}
});



// text rotator

jQuery(document).ready(function ($) {
	documentReady();
});



function printForms() {
	var divContents = $("#flush-custom1").html();
	var printWindow = window.open('', '', 'height=800,width=800');
	printWindow.document.write('<html><head>');
	printWindow.document.write('</head><body >');
	printWindow.document.write(divContents);
	printWindow.document.write('</body></html>');
	printWindow.document.close();
	printWindow.print();
	printWindow.close();
}

function documentReady() {
	//set animation timing
	var animationDelay = 2500,
		//loading bar effect
		barAnimationDelay = 3800,
		barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
		//letters effect
		lettersDelay = 50, e
	//type effect
	typeLettersDelay = 150,
		selectionDuration = 500,
		typeAnimationDelay = selectionDuration + 800,
		//clip effect 
		revealDuration = 600,
		revealAnimationDelay = 1500;

	initHeadline();



	function initHeadline() {
		//insert <i> element for each letter of a changing word
		singleLetters($('.cd-headline.letters').find('b'));
		//initialise headline animation
		animateHeadline($('.cd-headline'));
	}

	function singleLetters($words) {
		$words.each(function () {
			var word = $(this),
				letters = word.text().split(''),
				selected = word.hasClass('is-visible');
			for (i in letters) {
				if (word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>';
				letters[i] = (selected) ? '<i class="in">' + letters[i] + '</i>' : '<i>' + letters[i] + '</i>';
			}
			var newLetters = letters.join('');
			word.html(newLetters);
		});
	}

	function animateHeadline($headlines) {
		var duration = animationDelay;
		$headlines.each(function () {
			var headline = $(this);

			if (headline.hasClass('loading-bar')) {
				duration = barAnimationDelay;
				setTimeout(function () { headline.find('.cd-words-wrapper').addClass('is-loading') }, barWaiting);
			} else if (headline.hasClass('clip')) {
				var spanWrapper = headline.find('.cd-words-wrapper'),
					newWidth = spanWrapper.width() + 10
				spanWrapper.css('width', newWidth);
			} else if (!headline.hasClass('type')) {
				//assign to .cd-words-wrapper the width of its longest word
				var words = headline.find('.cd-words-wrapper b'),
					width = 0;
				words.each(function () {
					var wordWidth = $(this).width() + 20;
					if (wordWidth > width) width = wordWidth;
				});
				headline.find('.cd-words-wrapper').css('width', width);
			};

			//trigger animation
			setTimeout(function () { hideWord(headline.find('.is-visible').eq(0)) }, duration);
		});
	}

	function hideWord($word) {
		var nextWord = takeNext($word);

		if ($word.parents('.cd-headline').hasClass('type')) {
			var parentSpan = $word.parent('.cd-words-wrapper');
			parentSpan.addClass('selected').removeClass('waiting');
			setTimeout(function () {
				parentSpan.removeClass('selected');
				$word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
			}, selectionDuration);
			setTimeout(function () { showWord(nextWord, typeLettersDelay) }, typeAnimationDelay);

		} else if ($word.parents('.cd-headline').hasClass('letters')) {
			var bool = ($word.children('i').length >= nextWord.children('i').length) ? true : false;
			hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
			showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);

		} else if ($word.parents('.cd-headline').hasClass('clip')) {
			$word.parents('.cd-words-wrapper').animate({ width: '2px' }, revealDuration, function () {
				switchWord($word, nextWord);
				showWord(nextWord);
			});

		} else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
			$word.parents('.cd-words-wrapper').removeClass('is-loading');
			switchWord($word, nextWord);
			setTimeout(function () { hideWord(nextWord) }, barAnimationDelay);
			setTimeout(function () { $word.parents('.cd-words-wrapper').addClass('is-loading') }, barWaiting);

		} else {
			switchWord($word, nextWord);
			setTimeout(function () { hideWord(nextWord) }, animationDelay);
		}
	}

	function showWord($word, $duration) {
		if ($word.parents('.cd-headline').hasClass('type')) {
			showLetter($word.find('i').eq(0), $word, false, $duration);
			$word.addClass('is-visible').removeClass('is-hidden');

		} else if ($word.parents('.cd-headline').hasClass('clip')) {
			$word.parents('.cd-words-wrapper').animate({ 'width': $word.width() + 10 }, revealDuration, function () {
				setTimeout(function () { hideWord($word) }, revealAnimationDelay);
			});
		}
	}

	function hideLetter($letter, $word, $bool, $duration) {
		$letter.removeClass('in').addClass('out');

		if (!$letter.is(':last-child')) {
			setTimeout(function () { hideLetter($letter.next(), $word, $bool, $duration); }, $duration);
		} else if ($bool) {
			setTimeout(function () { hideWord(takeNext($word)) }, animationDelay);
		}

		if ($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
			var nextWord = takeNext($word);
			switchWord($word, nextWord);
		}
	}

	function showLetter($letter, $word, $bool, $duration) {
		$letter.addClass('in').removeClass('out');

		if (!$letter.is(':last-child')) {
			setTimeout(function () { showLetter($letter.next(), $word, $bool, $duration); }, $duration);
		} else {
			if ($word.parents('.cd-headline').hasClass('type')) { setTimeout(function () { $word.parents('.cd-words-wrapper').addClass('waiting'); }, 200); }
			if (!$bool) { setTimeout(function () { hideWord($word) }, animationDelay) }
		}
	}

	function takeNext($word) {
		return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
	}

	function takePrev($word) {
		return (!$word.is(':first-child')) ? $word.prev() : $word.parent().children().last();
	}

	function switchWord($oldWord, $newWord) {
		$oldWord.removeClass('is-visible').addClass('is-hidden');
		$newWord.removeClass('is-hidden').addClass('is-visible');
	}

	//this is for the demo only
	var intro = $('.cd-intro');
	$('.cd-filter input').on('change', function (event) {
		var selected = $(event.target).attr('id')
		switch (selected) {
			case 'rotate-1':
				intro.load('content.html .rotate-1', function () {
					initHeadline();
				});
				break;
			case 'type':
				intro.load('content.html .type', function () {
					initHeadline();
				});
				break;
			case 'rotate-2':
				intro.load('content.html .rotate-2', function () {
					initHeadline();
				});
				break;
			case 'loading-bar':
				intro.load('content.html .loading-bar', function () {
					initHeadline();
				});
				break;
			case 'slide':
				intro.load('content.html .slide', function () {
					initHeadline();
				});
				break;
			case 'clip':
				intro.load('content.html .clip', function () {
					initHeadline();
				});
				break;
			case 'zoom':
				intro.load('content.html .zoom', function () {
					initHeadline();
				});
				break;
			case 'rotate-3':
				intro.load('content.html .rotate-3', function () {
					initHeadline();
				});
				break;
			case 'scale':
				intro.load('content.html .scale', function () {
					initHeadline();
				});
				break;
			case 'push':
				intro.load('content.html .push', function () {
					initHeadline();
				});
				break;
		}
	});
	// alert("before removing Show");
	// setTimeout($( ".sm-hide" ).on( "click", function() {$("#navbarSupportedContent").removeClass("show");}), 5000);
}

function hideLanguageSwitch() {
	var languageDiv = document.getElementById('languageDiv');
	languageDiv.style.visibility = "hidden";

}

function showLanguageSwitch() {
	var languageDiv = document.getElementById('languageDiv');
	languageDiv.style.visibility = "";

}

function toggleLanguage() {
	document.getElementById("language").checked = false;
}

//Navbar Collapse on Mobile View
// const navLinks = document.querySelectorAll('.sm-hide')
// const menuToggle = document.getElementById('navbarSupportedContent')
// const bsCollapse = new bootstrap.Collapse(menuToggle, {toggle:false})
// navLinks.forEach((l) => {
//     l.addEventListener('click', () => { bsCollapse.toggle() })
// });